import React, { useState } from "react"
import translations from "/content/translations.json"

import I18nContext from "utils/i18nContext"

export default function I18n(props) {
  const [lang, setLang] = useState(props.defaultLang ? props.defaultLang : "fr")

  const translate = sentence => {
    return translations
      ? translations[sentence] && translations[sentence][lang]
        ? translations[sentence][lang]
        : sentence
      : ""
  }

  return (
    <I18nContext.Provider
      value={{
        lang,
        setLang: lang => {
          setLang(lang)
        },
        translations,
        translate,
      }}
    >
      {props.children}
    </I18nContext.Provider>
  )
}
