import React, { useState } from "react"

import SearchContext from "utils/searchContext"

export default function Search(props) {
  const [typeRecherche, setTypeRecherche] = useState("")
  const [date, setDate] = useState("")
  const [range, setRange] = useState(false)
  const [category, setCategory] = useState()
  const [moods, setMoods] = useState([])
  const [spaces, setSpaces] = useState([])
  const [genres, setGenres] = useState([])
  const [activities, setActivities] = useState([])
  const [tarifs, setTarifsRange] = useState()
  const [equipements, setEquipements] = useState([])
  const [typesStudio, setTypesStudio] = useState()
  const [typesLocation, setTypesLocation] = useState([])
  const [ids, setIds] = useState([])
  const [arrondissements, setArrondissements] = useState([])

  return (
    <SearchContext.Provider
      value={{
        typeRecherche,
        setTypeRecherche,
        date,
        setDate,
        range,
        setRange,
        category,
        setCategory,
        moods,
        setMoods,
        spaces,
        setSpaces,
        genres,
        setGenres,
        activities,
        setActivities,
        tarifs,
        setTarifsRange,
        equipements,
        setEquipements,
        typesStudio,
        setTypesStudio,
        typesLocation,
        setTypesLocation,
        ids,
        setIds,
        arrondissements,
        setArrondissements,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  )
}
