exports.venueProps = `
  id: databaseId
  title
  content
  slug
  featuredImage {
    node {
      sourceUrl
    }
  }
  type: contentTypeName
  acf: venuesAcfFields {
    adresse {
      city
      country
      countryShort
      lat: latitude
      lng: longitude
      placeId
      postCode
      state
      stateShort
      streetAddress
      streetName
      streetNumber
      zoom
    }
    description
    visuels {
      visuel {
        node {        
          id: databaseId
          alt: altText
          sourceUrl
        }
      }
    }
    horaires {
      lundi {
        ouvert
        horaires {
          ouverture
          fermeture
        }
        plageshoraires {
          ouverture
          fermeture
        }
      }
      mardi {
        ouvert
        horaires {
          ouverture
          fermeture
        }
        plageshoraires {
          ouverture
          fermeture
        }
      }
      mercredi {
        ouvert
        horaires {
          ouverture
          fermeture
        }
        plageshoraires {
          ouverture
          fermeture
        }
      }
      jeudi {
        ouvert
        horaires {
          ouverture
          fermeture
        }
        plageshoraires {
          ouverture
          fermeture
        }
      }
      vendredi {
        ouvert
        horaires {
          ouverture
          fermeture
        }
        plageshoraires {
          ouverture
          fermeture
        }
      }
      samedi {
        ouvert
        horaires {
          ouverture
          fermeture
        }
        plageshoraires {
          ouverture
          fermeture
        }
      }
      dimanche {
        ouvert
        horaires {
          ouverture
          fermeture
        }
        plageshoraires {
          ouverture
          fermeture
        }
      }
    }
    reseauxSociaux {
      facebook
      instagram
      soundcloud
      twitter
      youtube
    }
    siteWeb
    eligibleAuPassCulture
  }
  seo {
    canonicalUrl
    description
    openGraphDescription
    openGraphTitle
    openGraphType
    title
    twitterDescription
    twitterTitle
    socialImage {
      node {
        sourceUrl
      }
    }
  }
`

exports.genreProps = `
  genres {
    nodes {
      name
      slug
      id: databaseId
      acf: acfTaxonomyFilters {
        cacherPour
      }
    }
  }
`
exports.spaceProps = `
  spaces {
    nodes {
      name
      slug
      id: databaseId
      acf: acfTaxonomyFilters {
        cacherPour
      }
    }
  }
`
exports.moodProps = `
  moods {
    nodes {
      name
      slug
      id: databaseId
      acf: acfTaxonomyFilters {
        cacherPour
      }
    }
  }
`
exports.activityProps = `
  activities {
    nodes {
      name
      slug
      id: databaseId
      acf: acfTaxonomyFilters {
        cacherPour
      }
    }
  }
`

exports.userProps = `
firstName
lastName
name
email
roles {
  nodes {
    name
  }
}
acf: userAcfFields {
  livesFavoris {
    nodes {
      id: databaseId
    }
  }
  clubsFavoris {
    nodes {
      id: databaseId
    }
  }
  recordsFavoris {
    nodes {
      id: databaseId
    }
  }
  studiosFavoris {
    nodes {
      id: databaseId
    }
  }
  curateursFavoris {
    nodes {
      id: databaseId
    }
  }
  evenementsSauvegardes {
    nodes {
      type: contentTypeName
    }
  }
  filtresAlerteEvenement {
    nodes {
      id: databaseId
      slug
    }
  }
  notificationCurateurFavori
  notificationLieuFavori
  notificationEvenements
}
`
// exports.userProps = `
//   firstName
//   lastName
//   name
//   email
//   roles {
//     nodes {
//       name
//     }
//   }
//   acf: userAcfFields {
//     livesFavoris {
//       nodes {
//         title
//         slug
//         type: contentTypeName
//         id: databaseId
//       }
//     }
//     clubsFavoris {
//       nodes {
//         title
//         slug
//         type: contentTypeName
//         id: databaseId
//       }
//     }
//     recordsFavoris {
//       nodes {
//         title
//         slug
//         type: contentTypeName
//         id: databaseId
//       }
//     }
//     studiosFavoris {
//       nodes {
//         title
//         slug
//         type: contentTypeName
//         id: databaseId
//       }
//     }
//     curateursFavoris {
//       nodes {
//         title
//         slug
//         type: contentTypeName
//         id: databaseId
//       }
//     }
//     evenementsSauvegardes {
//       ... on Event {
//         title
//         slug
//         id: databaseId
//         type: contentTypeName
//         locale {
//           id
//         }
//         translated {
//           type: contentTypeName
//           databaseId
//           slug
//           locale {
//             id
//           }
//         }
//         acf: eventsAcfFields {
//           description
//           horaires {
//             debut
//             fin
//           }
//           lieu {
//             nodes {
//               id: databaseId
//               slug
//               type: contentTypeName
//             }
//           }
//         }
//       }
//     }
//     filtresAlerteEvenement {
//       id: databaseId
//       slug
//     }
//     notificationCurateurFavori
//     notificationLieuFavori
//     notificationEvenements
//   }
// `
