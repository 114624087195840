import axios from "axios"
import { userProps } from "queries/fragments"

const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_REACT_APP_API,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
})

export const login = async (email, password) => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        loginFromFrontend(input: {
          login: "${email}",
          password: "${password}"
        }) {
          user {
            ${userProps}
          }
        }
      }
    `,
  })
}

export const register = async (firstname, lastname, email, password) => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        registerFromFrontend(input: {
          firstname: "${firstname}",
          lastname: "${lastname}",
          email: "${email}",
          password: "${password}"
        }) {
          user {
            ${userProps}
          }
        }
      }
    `,
  })
}

export const logout = () => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        logout(input: {}){
          clientMutationId
          status
        }
      }
    `,
  })
}

export const getCurrentUser = async () => {
  return axiosInstance.post("graphql", {
    query: `
      query getUser {
        viewer {
          ${userProps}
        }
      }
    `,
  })
}

export const sendPasswordResetEmail = async username => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        sendPasswordResetEmail(input: { 
          username: "${username}" 
        }) {
          clientMutationId
        }
      }
    `,
  })
}

export const resetUserPassword = async (key, username, password) => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        resetUserPassword(input: {
          key: "${key}",
          login: "${username}",
          password: "${password}"
        }) {
          user {
            id
          }
        }
      }
    `,
  })
}

export const updateUserInfos = async (prenom, nom, email) => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        updateUserInfos(input: {
          firstname: "${prenom}",
          lastname: "${nom}",
          email: "${email}"
        }) {
          user {
            ${userProps}
          }
        }
      }
    `,
  })
}

export const updateUserPassword = async (email, password) => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        updateUserPassword(input: {
          email: "${email}"
          password: "${password}"
        }) {
          user {
            ${userProps}
          }
        }
      }
    `,
  })
}

export const deleteFavori = async id => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        deleteFavori(input: {
          id: ${id}
        }) {
          user {
            ${userProps}
          }
        }
      }
    `,
  })
}

export const addFavori = async id => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        addFavori(input: {
          id: ${id}
        }) {
          user {
            ${userProps}
          }
        }
      }
    `,
  })
}

export const updateUserNotification = async (id, value) => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        updateUserNotification(input: {
          id: "${id}",
          value: ${value}
        }) {
          user {
            ${userProps}
          }
        }
      }
    `,
  })
}
export const updateEventsFilters = async (action, id) => {
  return axiosInstance.post("graphql", {
    query: `
      mutation {
        updateEventsFilters(input: {
          action: "${action}",
          id: ${id},
        }) {
          user {
            ${userProps}
          }
        }
      }
    `,
  })
}
