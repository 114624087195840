import { graphql, useStaticQuery } from "gatsby"
import { useContext } from "react"
import I18nContext from "../utils/i18nContext"
import { getShortLocale } from "../utils/localization"

export const useSiteContent = () => {
  const { lang } = useContext(I18nContext)
  const {
    pages,
    clubs,
    lives,
    records,
    studios,
    curators,
    moods,
    spaces,
    genres,
    activities,
    typesStudio,
    typesLocation,
    equipements,
    taxonomies,
    options,
  } = useStaticQuery(graphql`
    {
      pages: allWpPage(
        filter: { databaseId: { nin: [1279, 1277, 1274, 4823] } }
        sort: { fields: menuOrder }
      ) {
        edges {
          node {
            id: databaseId
            slug
            locale {
              id
            }
            translated {
              type: contentTypeName
              databaseId
              slug
              locale {
                id
              }
            }
            title
          }
        }
      }
      clubs: allWpClub {
        edges {
          node {
            id: databaseId
            slug
            locale {
              id
            }
            translated {
              type: contentTypeName
              databaseId
              slug
              locale {
                id
              }
            }
            title
            type: contentTypeName
            content
            featuredImage {
              node {
                sourceUrl
              }
            }
            acf: venuesAcfFields {
              adresse {
                city
                country
                countryShort
                lat: latitude
                lng: longitude
                placeId
                postCode
                state
                stateShort
                streetAddress
                streetName
                streetNumber
                zoom
              }
              description
              visuels {
                visuel {
                  node {
                    id: databaseId
                    alt: altText
                    sourceUrl
                  }
                }
              }
              horaires {
                lundi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                mardi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                mercredi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                jeudi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                vendredi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                samedi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                dimanche {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
              }
              reseauxSociaux {
                facebook
                instagram
                soundcloud
                twitter
                youtube
              }
              siteWeb
              eligibleAuPassCulture
            }
            genres {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            spaces {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            moods {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            seo {
              canonicalUrl
              description
              openGraphDescription
              openGraphTitle
              openGraphType
              title
              twitterDescription
              twitterTitle
              socialImage {
                sourceUrl
              }
            }
          }
        }
      }
      lives: allWpLive {
        edges {
          node {
            id: databaseId
            slug
            locale {
              id
            }
            translated {
              type: contentTypeName
              databaseId
              slug
              locale {
                id
              }
            }
            title
            type: contentTypeName
            content
            featuredImage {
              node {
                sourceUrl
              }
            }
            acf: venuesAcfFields {
              adresse {
                city
                country
                countryShort
                lat: latitude
                lng: longitude
                placeId
                postCode
                state
                stateShort
                streetAddress
                streetName
                streetNumber
                zoom
              }
              description
              visuels {
                visuel {
                  node {
                    id: databaseId
                    alt: altText
                    sourceUrl
                  }
                }
              }
              horaires {
                lundi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                mardi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                mercredi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                jeudi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                vendredi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                samedi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                dimanche {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
              }
              reseauxSociaux {
                facebook
                instagram
                soundcloud
                twitter
                youtube
              }
              siteWeb
              eligibleAuPassCulture
            }
            spaces {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            moods {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            genres {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            seo {
              canonicalUrl
              description
              openGraphDescription
              openGraphTitle
              openGraphType
              title
              twitterDescription
              twitterTitle
              socialImage {
                sourceUrl
              }
            }
          }
        }
      }
      records: allWpRecord {
        edges {
          node {
            id: databaseId
            slug
            locale {
              id
            }
            translated {
              type: contentTypeName
              databaseId
              slug
              locale {
                id
              }
            }
            title
            type: contentTypeName
            content
            featuredImage {
              node {
                sourceUrl
              }
            }
            acf: venuesAcfFields {
              adresse {
                city
                country
                countryShort
                lat: latitude
                lng: longitude
                placeId
                postCode
                state
                stateShort
                streetAddress
                streetName
                streetNumber
                zoom
              }
              description
              visuels {
                visuel {
                  node {
                    id: databaseId
                    alt: altText
                    sourceUrl
                  }
                }
              }
              horaires {
                lundi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                mardi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                mercredi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                jeudi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                vendredi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                samedi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                dimanche {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
              }
              reseauxSociaux {
                facebook
                instagram
                soundcloud
                twitter
                youtube
              }
              siteWeb
              eligibleAuPassCulture
            }
            spaces {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            genres {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            seo {
              canonicalUrl
              description
              openGraphDescription
              openGraphTitle
              openGraphType
              title
              twitterDescription
              twitterTitle
              socialImage {
                sourceUrl
              }
            }
          }
        }
      }
      studios: allWpStudio {
        edges {
          node {
            id: databaseId
            slug
            locale {
              id
            }
            translated {
              type: contentTypeName
              databaseId
              slug
              locale {
                id
              }
            }
            title
            type: contentTypeName
            content
            featuredImage {
              node {
                sourceUrl
              }
            }
            acf: venuesAcfFields {
              adresse {
                city
                country
                countryShort
                lat: latitude
                lng: longitude
                placeId
                postCode
                state
                stateShort
                streetAddress
                streetName
                streetNumber
                zoom
              }
              description
              visuels {
                visuel {
                  node {
                    id: databaseId
                    alt: altText
                    sourceUrl
                  }
                }
              }
              horaires {
                lundi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                mardi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                mercredi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                jeudi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                vendredi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                samedi {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
                dimanche {
                  ouvert
                  horaires {
                    ouverture
                    fermeture
                  }
                  plageshoraires {
                    ouverture
                    fermeture
                  }
                }
              }
              reseauxSociaux {
                facebook
                instagram
                soundcloud
                twitter
                youtube
              }
              siteWeb
              eligibleAuPassCulture
            }
            spaces {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            activities {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            equipements {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            typesLocation {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            typesStudio {
              nodes {
                name
                slug
                id: databaseId
                acf: acfTaxonomyFilters {
                  cacherPour
                }
              }
            }
            seo {
              canonicalUrl
              description
              openGraphDescription
              openGraphTitle
              openGraphType
              title
              twitterDescription
              twitterTitle
              socialImage {
                sourceUrl
              }
            }
          }
        }
      }
      curators: allWpCurator {
        edges {
          node {
            title
            id: databaseId
            slug
            locale {
              id
            }
            translated {
              type: contentTypeName
              databaseId
              slug
              locale {
                id
              }
            }
            acf: curatorsAcfFields {
              description
              evenements {
                nodes {
                  id: databaseId
                }
              }
              fieldGroupName: __typename
              logo {
                node {
                  sourceUrl
                }
              }
              reseauxSociaux {
                facebook
                instagram
                soundcloud
                twitter
                youtube
              }
              siteWeb
              typeDeCurateur
              typeDevenementsRecommandes
            }
          }
        }
      }
      moods: allWpMood {
        edges {
          node {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
            locale {
              id
            }
          }
        }
      }
      spaces: allWpSpace {
        edges {
          node {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
            locale {
              id
            }
          }
        }
      }
      genres: allWpGenre {
        edges {
          node {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
            locale {
              id
            }
          }
        }
      }
      activities: allWpActivity {
        edges {
          node {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
            locale {
              id
            }
          }
        }
      }
      typesStudio: allWpTypeStudio {
        edges {
          node {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
            locale {
              id
            }
          }
        }
      }
      typesLocation: allWpTypeLocation {
        edges {
          node {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
            locale {
              id
            }
          }
        }
      }
      equipements: allWpEquipement {
        edges {
          node {
            name
            slug
            id: databaseId
            acf: acfTaxonomyFilters {
              cacherPour
            }
            locale {
              id
            }
          }
        }
      }
      taxonomies: allWpTaxonomy {
        edges {
          node {
            name
            terms: connectedTerms {
              nodes {
                name
                slug
                id: databaseId
              }
            }
          }
        }
      }
      options: wpOptions {
        acfTarifs {
          tranche {
            name: nom
            tarifMaximum
            tarifMinimum
          }
        }
      }
    }
  `)

  const makeNodeWithTranslationsObject = node => {
    const translations = {}
    if (node.translated.length > 0) {
      node.translated.forEach(translation => {
        const transationLocale = getShortLocale(translation.locale.id)
        translations[transationLocale] = {
          id: translation.databaseId,
          type: translation.type,
          slug: translation.slug,
        }
      })
    }

    node.translations = translations

    return node
  }

  return {
    pages: pages.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang)
      .map(node => makeNodeWithTranslationsObject(node)),
    clubs: clubs.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang)
      .map(node => makeNodeWithTranslationsObject(node)),
    lives: lives.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang)
      .map(node => makeNodeWithTranslationsObject(node)),
    studios: studios.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang)
      .map(node => makeNodeWithTranslationsObject(node)),
    records: records.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang)
      .map(node => makeNodeWithTranslationsObject(node)),
    curators: curators.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang)
      .map(node => makeNodeWithTranslationsObject(node)),
    moods: moods.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang),
    spaces: spaces.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang),
    genres: genres.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang),
    activities: activities.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang),
    typesStudio: typesStudio.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang),
    typesLocation: typesLocation.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang),
    equipements: equipements.edges
      .map(node => node.node)
      .filter(node => getShortLocale(node.locale?.id) === lang),
    taxonomies: taxonomies.edges.map(group => {
      return {
        slug: group.node.name,
        terms: group.node.terms.nodes.map(term => {
          return {
            name: term.name,
            slug: term.slug,
            id: term.id,
          }
        }),
      }
    }),
    options,
  }
}
