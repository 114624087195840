import React, { useContext } from "react"

import { EmbedStyle, GlobalStyle } from "utils/styles"
import "src/utils/fonts.css"
import { CookieNotice } from "gatsby-cookie-notice"
import I18nContext from "../utils/i18nContext"
import useWindowSize from "../hooks/useWindowSize"

function DefaultLayout({ children, pageContext }) {
  const { translate } = useContext(I18nContext)
  const windowSize = useWindowSize()
  console.log(pageContext)
  return (
    <main
      className={"main-layout"}
      style={{
        height: windowSize.height ? windowSize.height + "px" : "100dvh",
        overflow: pageContext?.isTypePage ? "auto" : "hidden",
      }}
    >
      <GlobalStyle />
      {children}
      <CookieNotice
        cookies={[
          {
            name: "necessary",
            editable: false,
            default: true,
            title: "Essentiel",
            text: translate("Essential cookies"),
          },
          {
            name: "gatsby-gdpr-google-tagmanager",
            editable: true,
            default: true,
            title: "Google Analytics",
            text: translate("Google Analytics"),
          },
        ]}
        acceptButtonText={translate("Accept")}
        declineButtonText={translate("Decline")}
        personalizeButtonText={translate("Personalize")}
        personalizeValidationText={translate("Validate")}
      >
        <h4>{translate("This websites uses cookies.")}</h4>
        <p>{translate("Cookie Banner Text")}</p>
      </CookieNotice>
    </main>
  )
}

function EmbedLayout({ children }) {
  return (
    <main className={"embed-layout"}>
      <EmbedStyle />
      {children}
    </main>
  )
}

export default function Layouts({ children, pageContext }) {
  if (pageContext.layout === "embed") {
    return <EmbedLayout>{children}</EmbedLayout>
  }
  return <DefaultLayout pageContext={pageContext}>{children}</DefaultLayout>
}
