import { useCallback, useEffect, useState } from "react"
import { window } from "browser-monads"
import moment from "moment"
import { useSiteContent } from "./useSiteContent"

function getSessionStorageOrDefault(defaultValue) {
  const stored = window.sessionStorage.getItem("MIPUserProfile")
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export default function useUserProfile() {
  const [profile, setProfile] = useState(getSessionStorageOrDefault({}))

  const setProfileFromUser = useCallback(user => {
    if (!user) {
      setProfile({})
      return
    }

    const userEvents = user.acf.evenementsSauvegardes.nodes

    setProfile({
      prenom: user.firstName,
      nom: user.lastName,
      nomComplet: user.name,
      email: user.email,
      role: user.roles.nodes[0].name,
      preferences: {
        clubsFavoris: user.acf.clubsFavoris?.nodes
          ? user.acf.clubsFavoris.nodes.filter(Boolean)
          : [],
        curateursFavoris: user.acf.curateursFavoris?.nodes
          ? user.acf.curateursFavoris.nodes.filter(Boolean)
          : [],
        evenementsSauvegardes: userEvents,
        filtresAlerteEvenement: user.acf.filtresAlerteEvenement ?? [],
        livesFavoris: user.acf.livesFavoris?.nodes
          ? user.acf.livesFavoris.nodes.filter(Boolean)
          : [],
        recordsFavoris: user.acf.recordsFavoris?.nodes
          ? user.acf.recordsFavoris.nodes.filter(Boolean)
          : [],
        studiosFavoris: user.acf.studiosFavoris?.nodes
          ? user.acf.studiosFavoris.nodes.filter(Boolean)
          : [],
        notificationCurateurFavori:
          user.acf.notificationCurateurFavori ?? false,
        notificationEvenements: user.acf.notificationEvenements ?? false,
        notificationLieuFavori: user.acf.notificationLieuFavori ?? false,
      },
    })
  }, [])

  useEffect(() => {
    window.sessionStorage.setItem("MIPUserProfile", JSON.stringify(profile))
  }, [profile])

  return [profile, setProfileFromUser]
}
