import React from "react"

export default React.createContext({
  isLoggedIn: null,
  setIsLoggedIn: () => {},
  authToken: null,
  setAuthToken: () => {},
  refreshToken: null,
  setRefreshToken: () => {},
  profile: null,
  setProfile: () => {},
})
