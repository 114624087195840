exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-curators-js": () => import("./../../../src/pages/curators.js" /* webpackChunkName: "component---src-pages-curators-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-views-embed-js": () => import("./../../../src/views/Embed.js" /* webpackChunkName: "component---src-views-embed-js" */),
  "component---src-views-page-js": () => import("./../../../src/views/Page.js" /* webpackChunkName: "component---src-views-page-js" */),
  "component---src-views-venues-js": () => import("./../../../src/views/Venues.js" /* webpackChunkName: "component---src-views-venues-js" */)
}

