import React, { useEffect, useState } from "react"
import UserContext from "utils/UserContext"
import { getCurrentUser } from "../../services/auth"
import useUserProfile from "../../hooks/useUserProfile"

export default function User(props) {
  const [profile, setProfile] = useUserProfile()
  const [isLoggedIn, setIsLoggedIn] = useState(!!profile.email)

  // Get current user on load to check if really logged in
  useEffect(() => {
    getCurrentUser().then(response => {
      const result = response.data
      const user = result?.data?.viewer
      const loggedIn = Boolean(user)
      // if (loggedIn !== isLoggedIn) {
      setIsLoggedIn(loggedIn)
      if (loggedIn) {
        setProfile(user)
      } else {
        setProfile(null)
      }
      // }
    })
  }, [isLoggedIn, setProfile])

  // Clear data on logout
  useEffect(() => {
    if (!isLoggedIn) {
      setProfile(null)
    }
  }, [isLoggedIn, setProfile])

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        profile,
        setProfile,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
