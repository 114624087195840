import React from "react"

export default React.createContext({
  typeRecherche: null,
  setTypeRecherche: () => "",
  date: null,
  setDate: () => "",
  range: null,
  setRange: () => "",
  category: null,
  setCategory: () => {},
  moods: null,
  setMoods: () => {},
  spaces: null,
  setSpaces: () => {},
  genres: null,
  setGenres: () => {},
  activities: null,
  setActivities: () => {},
  tarifs: null,
  setTarifsRange: () => {},
  equipements: null,
  setEquipements: () => {},
  typesStudio: null,
  setTypesStudio: () => {},
  typesLocation: null,
  setTypesLocation: () => {},
  ids: [],
  setIds: () => {},
  arrondissements: [],
  setArrondissements: () => [],
})
