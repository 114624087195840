exports.extraLanguages = ["en"]

const userStrings = {
  slug: {
    en: "account",
    fr: "compte",
  },
  profileSlug: {
    en: "profile",
    fr: "profil",
  },
  registerSlug: {
    en: "register",
    fr: "inscription",
  },
  loginSlug: {
    en: "login",
    fr: "connexion",
  },
  resetpasswordSlug: {
    en: "resetpassword",
    fr: "reinitialisationmotdepasse",
  },
}
exports.userStrings = userStrings

const curatorsStrings = {
  slug: {
    en: "curators",
    fr: "curateurs",
  },
}
exports.curatorsStrings = curatorsStrings

const searchStrings = {
  slug: {
    en: "search",
    fr: "recherche",
  },
}
exports.searchStrings = searchStrings

const getShortLocale = locale => {
  switch (locale) {
    case "fr_FR":
      return "fr"

    case "en_US":
      return "en"

    case "fr":
      return "fr"

    case "en":
      return "en"

    default:
      return "fr"
  }
}

exports.getShortLocale = getShortLocale

exports.shortToLongLocale = shortLocale => {
  switch (shortLocale) {
    case "fr":
      return "fr_FR"

    case "en":
      return "en_US"

    default:
      return "fr_FR"
  }
}

exports.makeLocalePath = (type = "page", slug = "", locale = "fr_FR") => {
  const shortLocale = getShortLocale(locale)
  switch (type) {
    case "page":
      if (shortLocale === "fr") {
        if (typeof slug === `object`) {
          return `/pages/${slug[shortLocale]}`
        } else {
          return `/pages/${slug}`
        }
      } else {
        if (typeof slug === `object`) {
          return `/${shortLocale}/pages/${slug[shortLocale]}`
        } else {
          return `/${shortLocale}/pages/${slug}`
        }
      }

    case "gatsbyPage":
      if (shortLocale === "fr") {
        return `/${slug}`
      } else {
        return `/${shortLocale}/${slug}`
      }

    case "live":
    case "lives":
      if (shortLocale === "fr") {
        return `/live/${slug}`
      } else {
        return `/${shortLocale}/live/${slug}`
      }

    case "club":
    case "clubs":
      if (shortLocale === "fr") {
        return `/club/${slug}`
      } else {
        return `/${shortLocale}/club/${slug}`
      }

    case "record":
    case "records":
      if (shortLocale === "fr") {
        return `/record/${slug}`
      } else {
        return `/${shortLocale}/record/${slug}`
      }

    case "studio":
    case "studios":
      if (shortLocale === "fr") {
        return `/studio/${slug}`
      } else {
        return `/${shortLocale}/studio/${slug}`
      }

    case "embed":
      if (shortLocale === "fr") {
        return `/embed/${slug}`
      } else {
        return `/${shortLocale}/embed/${slug}`
      }

    case "user":
      if (shortLocale === "fr") {
        switch (slug) {
          case "profile":
            return `/${userStrings.slug[shortLocale]}/${userStrings.profileSlug[shortLocale]}/`

          case "resetpassword":
            return `/${userStrings.slug[shortLocale]}/${userStrings.resetpasswordSlug[shortLocale]}/`

          case "register":
            return `/${userStrings.slug[shortLocale]}/${userStrings.registerSlug[shortLocale]}/`

          case "login":
            return `/${userStrings.slug[shortLocale]}/${userStrings.loginSlug[shortLocale]}/`

          default:
            return `/${userStrings.slug[shortLocale]}/${userStrings.profileSlug[shortLocale]}/`
        }
      } else {
        switch (slug) {
          case "profile":
            return `/${shortLocale}/${userStrings.slug[shortLocale]}/${userStrings.profileSlug[shortLocale]}/`

          case "resetpassword":
            return `/${shortLocale}/${userStrings.slug[shortLocale]}/${userStrings.resetpasswordSlug[shortLocale]}/`

          case "register":
            return `/${shortLocale}/${userStrings.slug[shortLocale]}/${userStrings.registerSlug[shortLocale]}/`

          case "login":
            return `/${shortLocale}/${userStrings.slug[shortLocale]}/${userStrings.loginSlug[shortLocale]}/`

          default:
            return `/${shortLocale}/${userStrings.slug[shortLocale]}/${userStrings.profileSlug[shortLocale]}/`
        }
      }

    case "curator":
    case "curators":
      if (shortLocale === "fr") {
        return `/${curatorsStrings.slug[shortLocale]}/${slug}`
      } else {
        return `/${shortLocale}/${curatorsStrings.slug[shortLocale]}/${slug}`
      }

    default:
      if (shortLocale === "fr") {
        return ""
      } else {
        return `/${shortLocale}`
      }
  }
}

exports.makeCurrentLocationObject = (type, translations) => {
  return {
    type,
    translations,
  }
}
